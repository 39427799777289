import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import loadable from '@loadable/component'

//
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../lib/helpers'
import { Container, Row, Column } from '../components/structure'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import BlockContent from '../components/block-content'
import Sidebar from '../components/elements/sidebar'
// import FormQuote from '../components/elements/form-quote'
//
const PageHero = loadable(() => import('../components/page-hero'))

export const query = graphql`
  query ContactPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    page: sanityPage(slug: { current: { regex: "/(drafts.|)contact/" } }) {
      id
      title
      _rawBody
    }
  }
`

const ContactPage = props => {
  const { data, errors } = props
  // const [childData, setChildData] = useState('')

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = data && data.site
  const { title, _rawBody } = data && data.page
  // console.log('data.page', data.page)
  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:4444 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />

      <Container>
        <Row cols={3}>
          <Column colSpan={2}>
            <h1 className={`text-5xl font-display font-bold mb-0 text-brand-mainalpha70`}>
              {title}
            </h1>
            <BlockContent blocks={_rawBody || []} withFirst />
          </Column>
          <Sidebar>{/* <FormQuote compact /> */}</Sidebar>
        </Row>
      </Container>
    </Layout>
  )
}

export default ContactPage
